import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Navbar from "../Navbar";
// import { UserContext } from "../context/UserContext";
// import Navbar from "./Navbar";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import FMFooter from "../footer/FMFooter";
import Footer from "../footer/Footer";
import CustomSpinner from "../CustomSpinner";
// import CustomSpinner from "./CustomSpinner";

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: "toast-top-right",
  timeOut: 5000,
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

const notify = () => {
  toastr.success("Account number added successfully.", "Success Message");
};

const displayError1 = () => {
  toastr.warning(
    "Account number upload failed, check your details.",
    "Try again later"
  );
};

function AddAccountDetail() {
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState({});
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [userType, setUserType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.logisticsName) {
      setUserType("logistics");
    } else if (userInfo.storeName) {
      setUserType("store");
    } else if (userInfo.farmName) {
      setUserType("farm");
    } else if (userInfo.companyName) {
      setUserType("company");
    } else {
      setUserType("user");
    }
  }, [userInfo]);

  const fetchBanks = async () => {
    setIsLoading(true);
    try {
      const response = await axios({
        url: "/api/v1/general/bank",
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data && response.data.data) {
        setBanks(response.data.data);
      } else {
        console.error("Unexpected response format:", response);
        toastr.error("Failed to fetch banks. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching banks:", error.message);
      toastr.error("Error fetching bank list. Please check your network.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  useEffect(() => {
    let updatedUserType = "";
    if (userInfo.logisticsName) {
      updatedUserType = "logistics";
    } else if (userInfo.storeName) {
      updatedUserType = "store";
    } else if (userInfo.companyName) {
      updatedUserType = "company";
    } else if (userInfo.farmName) {
      updatedUserType = "farm";
    } else {
      updatedUserType = "user";
    }
    setUserType(updatedUserType);
  }, [userInfo]);

  const handleInputChange = (e) => {
    const bank = banks.find((bank) => bank.code === e.target.value);
    setSelectedBank(bank || {});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { code: bankCode, name: bankName } = selectedBank;

      if (!bankName || !bankCode || !accountNumber || !accountName) {
        console.error("Please select a bank and enter account details.");
        return;
      }

      await axios({
        url: `/api/v1/${userType}/bank`,
        data: {
          bankName,
          bankCode,
          accountNumber,
          accountName,
        },
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const profileResponse = await axios({
        url: `/api/v1/${userType}/profile`,
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "GET",
      });
      const userInfoData = profileResponse.data;
      setUserInfo(userInfoData);
      setIsLoading(false);
      // console.log(userInfoData);
      notify();
      navigate("/myprofile");
    } catch (error) {
      setIsLoading(false);
      displayError1();
      console.error("Error submitting bank details: ", error);
    }
  };

  return (
    <div>
      <Navbar />
      {isLoading ? (
        <CustomSpinner isLoading={isLoading} />
      ) : (
        <div style={{ minHeight: "70%", width: "100%" }}>
          <div className="container1">
            <form className="post_blog" onSubmit={handleSubmit}>
              <h2>Input Bank Details</h2>

              <div className="waitlist_post">
                <label className="form_label">Select Bank:</label>
                <select
                  value={selectedBank.code}
                  onChange={handleInputChange}
                  className="form_input"
                >
                  <option value="">Select Bank</option>
                  {/**
                 * used index as key instead of bank.code
                 because react console complains about two banks with the same key (bank.code) 
            */}
                  {banks.map((bank, index) => (
                    <option key={index} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="waitlist_post">
                <label className="form_label" htmlFor="accountNumber">
                  Account Number
                </label>
                <input
                  type="text"
                  name="accountNumber"
                  className="form_input"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
              <div className="waitlist_post">
                <label className="form_label" htmlFor="accountNumber">
                  Account Name
                </label>
                <input
                  type="text"
                  name="accountName"
                  className="form_input"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                />
              </div>
              <button className="pos_bt" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default AddAccountDetail;
