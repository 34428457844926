import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { HeadProvider, Meta } from "react-head";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/footer/Footer";
import { FaStar, FaRegStar } from "react-icons/fa";
import { BsFacebook, BsCart3 } from "react-icons/bs";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";
import { CiShoppingBasket } from "react-icons/ci";
import { UserContext } from "../../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../styles/productDetail.css";

export default function StoreProductPage() {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cart, setCart] = useState([]);
  const [quantity, setQuantity] = useState(1);
  // const [errorMessage, setErrorMessage] = useState("");

  const { slug, prodSlug } = useParams();

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    onTop();
    const fetchProductDetail = async () => {
      try {
        const response = await axios.get(
          `/api/v1/storeproducts/${slug}/${prodSlug}`
        );
        const productData = response.data.product;
        setProduct(productData);
        setRelatedProduct(response.data.relatedProducts);
        setSelectedImage(productData.images[0]);
      } catch (error) {
        console.error("Error fetching product detail:", error);
      }
    };

    fetchProductDetail();
  }, [slug, prodSlug]);

  // const isAuthenticated = () => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   return !!userInfo && userInfo.username;
  // };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get("/api/v1/storecart", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCart(response.data.cart);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    };

    fetchCartItems();
  }, []);

  const handleChatWithStore = async () => {
    try {
      if (!userInfo?._id) {
        return navigate("/signin");
      }
      const userId = userInfo._id;
      const storeId = product?.userId;

      const response = await axios.post(
        "/api/v1/chat/create",
        {
          participants: [userId, storeId],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const chatId = response.data.chat._id;
      if (chatId) {
        navigate(`/chat/${chatId}`);
      }
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  const shareProduct = (platform) => {
    const url = window.location.href;
    const title = product.productName;
    const description = product.productDescription;

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          "_blank"
        );
        break;
      case "instagram":
        // Implement Instagram sharing logic here
        break;
      case "twitter":
        const tweetText = `${title} - ${description}  ${url}`;
        window.open(
          `https://twitter.com/intent/tweet?text=${tweetText}`,
          "_blank"
        );
        break;
      case "whatsapp":
        const whatsappText = `${title} - ${description} ${url}`;
        window.open(
          `https://api.whatsapp.com/send?text=${whatsappText}`,
          "_blank"
        );
        break;
      case "linkedin":
        // Implement LinkedIn sharing logic here
        break;
      default:
        break;
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleAddToCart = async () => {
    try {
      if (!userInfo?._id) {
        toast.error("Please sign in to add items to your cart.", {
          autoClose: 5000,
        });
        navigate("/signin"); // Redirect to sign-in page
        return;
      }

      const userLocation = await getUserLocation();

      const addToCartResponse = await axios.post(
        "/api/v1/storecart",
        {
          product: product._id,
          quantity,
          userLocation,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (addToCartResponse.data.error) {
        toast.error(addToCartResponse.data.error, { autoClose: 5000 });
        return;
      }

      setCart(addToCartResponse.data.cart);
      toast.success("Item added to cart successfully!", { autoClose: 3000 });
    } catch (error) {
      console.error("Error adding to cart:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error, { autoClose: 5000 });
      } else {
        toast.error("Error adding item to cart. Please try again later.", {
          autoClose: 5000,
        });
      }
    }
  };

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            const userLocation = [latitude, longitude]; // Changed to array format
            resolve(userLocation);
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.error("User denied the request for Geolocation.");
                reject(error);
                break;
              case error.POSITION_UNAVAILABLE:
                console.error("Location information is unavailable.");
                reject(error);
                break;
              case error.TIMEOUT:
                console.error("The request to get user location timed out.");
                reject(error);
                break;
              default:
                console.error(
                  "An unknown error occurred while retrieving user location."
                );
                reject(error);
            }
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const rating =
    product && product.numReviews ? (product.numReviews / 5).toFixed(1) : "0.0";

  let number = product && product.price;
  let x = Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    number
  );

  const stars = [];
  for (let index = 0; index < 5; index++) {
    stars.push(
      index < Math.floor(rating) ? (
        <FaStar key={index} />
      ) : (
        <FaRegStar key={index} />
      )
    );
  }

  const isCartEmpty = cart.length === 0;

  return (
    <div>
      <Navbar />
      <HeadProvider>
        <Meta charSet="utf-8" />
        <title>{product?.productName}</title>
        <Meta
          name="description"
          content={`Welcome to ${product?.productName}`}
        />
        <Meta property="og:title" content={product?.productName} />
        <Meta property="og:image" content={product?.images?.[0]} />
      </HeadProvider>
      <div className="detailPageContainer">
        <div className="detailPage">
          <div className="detailContainer">
            {product && (
              <div className="wholeProductCentent">
                <div className="topContCont">
                  <div className="topContainer">
                    <h1 className="h1NoPad">{product.productName}</h1>
                    <span className="topUnder">
                      <span style={{ color: "black" }}>Brand : </span>
                      {slug}
                    </span>{" "}
                    |
                    <span className="topUnder">
                      <span style={{ color: "black" }}>Rating : </span>
                      <span className="">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index}>
                            {index < Math.floor(rating) ? (
                              <FaStar />
                            ) : (
                              <FaRegStar />
                            )}
                          </span>
                        ))}
                        <span className="topUnder"> {rating} stars</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <button
                      className="chatButton"
                      onClick={handleChatWithStore}
                    >
                      Chat with store
                    </button>
                  </div>
                </div>

                <div className="productDetail">
                  <div>
                    <div>
                      <img
                        src={selectedImage}
                        alt={product.productName}
                        className="mainImage"
                      />
                    </div>
                    <div>
                      {product.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={product.productName}
                          onClick={() => handleImageClick(image)}
                          className="otherImages"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="productBody">
                    <h3>
                      &#x20A6;{x} per {product.measuringScale}
                    </h3>
                    <p className="productStock">in-Stock</p>

                    <p className="productDescription">
                      {product.productDescription}
                    </p>
                    <div className="cartControl">
                      <div className="quantity-control">
                        <button onClick={handleDecrement}> - </button>
                        <span>
                          {quantity} {product.measuringScale}
                        </span>
                        <button onClick={handleIncrement}>+</button>
                      </div>

                      <button className="cartButton" onClick={handleAddToCart}>
                        Add to Cart
                      </button>
                      <Link className="chatButton" to={`/store/${slug}`}>
                        Keep shopping
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="shareButtons">
                  <button className="report">Report Abuse</button>
                  <div className="shareB">
                    <span>Share: </span>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("facebook")}
                    >
                      <BsFacebook size={18} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("instagram")}
                    >
                      <BiLogoInstagramAlt size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("twitter")}
                    >
                      <AiFillTwitterCircle size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("whatsapp")}
                    >
                      <IoLogoWhatsapp size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("linkedin")}
                    >
                      <AiFillLinkedin size={20} />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="cart-section">
            <h3>Cart</h3>
            {isCartEmpty ? (
              <div className="empty-cart">
                <p>Your cart is empty</p>
                <BsCart3 size={80} />
              </div>
            ) : (
              <div className="detailCart">
                <table>
                  <tbody>
                    {cart.items.map((item, index) => (
                      <tr key={index}>
                        <td className="detailCartEach">
                          <img
                            src={item.product?.images?.[0]}
                            alt={item.product?.productName}
                            className="otherImages"
                          />
                        </td>
                        <td className="detailCartEach">
                          {item.product?.productName}
                        </td>
                        <td className="detailCartEach">
                          <p className="deatilCartCunt">
                            {item.quantity} {item.product?.measuringScale}
                          </p>
                        </td>
                        <td className="detailCartEach">
                          <h5>
                            &#x20A6;
                            {parseFloat(
                              item.quantity * item.product?.price
                            ).toLocaleString("en")}
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="detailCartTotal">
                  <h3>
                    Current Total: &#x20A6;
                    {parseFloat(cart.totalAmount).toLocaleString("en")}
                  </h3>
                </div>
              </div>
            )}
          </div>
        </div>
        {relatedProducts && relatedProducts.length > 0 && (
          <div>
            <h3 className="relatedh3">Related Products:</h3>
            <div className="relatedProduct">
              {relatedProducts.map((relatedProduct) => (
                <Link
                  key={relatedProduct._id}
                  className="eachproduct"
                  to={`/store/${slug}/${relatedProduct.slug}`}
                >
                  <img
                    src={
                      relatedProduct.images[0]
                        ? relatedProduct.images[0]
                        : relatedProduct.avatar
                    }
                    alt={relatedProduct.productName}
                    className="productImage"
                  />
                  {relatedProduct?.storeName && (
                    <p className="farmname">{relatedProduct.storeName}</p>
                  )}
                  <p className="productname"> {relatedProduct.productName}</p>
                  <div className="ratingc">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <span key={index}>
                        {index < Math.floor(rating) ? (
                          <FaStar />
                        ) : (
                          <FaRegStar />
                        )}
                      </span>
                    ))}
                    <span className="rating"> {rating} stars</span>
                  </div>
                  <p className="scale">Price: &#x20A6;{relatedProduct.price}</p>
                  <div className="addToCart">
                    <CiShoppingBasket size="16px" />
                    <span className="scale"> {"  "}Add To Cart</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
}
