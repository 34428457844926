import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa"; // Menu (hamburger) icon
import { MdClose } from "react-icons/md"; // Close (cancel) icon
import logo from "../static/logo.png";

const navItems = [
  {
    title: "Explore",
    route: "/farmersmarket",
  },
  {
    title: "Login",
    route: "/signin",
  },
  {
    title: "Create account",
    route: "/signup",
  },
];

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <header className="headerContainer">
      <div className="headerSubContainer">
        <div className="headerLogo">
          <img src={logo} alt="Logo" />
        </div>

        {/* Navigation */}
        <nav className={`nav ${toggleMenu ? "" : "hidden"}`}>
          {navItems.map((item) => (
            <NavLink
              key={item.route}
              to={item.route}
              className={({ isActive }) =>
                `nav-item ${isActive ? "active" : ""}`
              }
              end
            >
              <p>{item.title}</p>
            </NavLink>
          ))}
        </nav>

        {/* Menu Button */}
        <button className="menu-button" onClick={handleToggleMenu}>
          {toggleMenu ? (
            <MdClose size={24} color="black" /> // Close icon
          ) : (
            <FaBars size={24} color="black" /> // Menu icon
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
