import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { UserContext } from "../../context/UserContext";

// import Cookies from "universal-cookie";

import { css } from "@emotion/react"; // Import the loader
import { ClipLoader } from "react-spinners"; // Import the loader

function SignILForm() {
  const navigate = useNavigate();

  // const cookies = new Cookies();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { setUserInfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const displayError = (err) => {
    toastr.warning(err, "Login Failed");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // cookies.set("jwt", { path: "/" });

    try {
      const loginResponse = await axios({
        url: "/api/v1/logistics/auth",
        withCredentials: true,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ email, password }),
      });

      // Store the token in localStorage
      localStorage.setItem("token", loginResponse.data.token);

      const profileResponse = await axios({
        url: "/api/v1/logistics/profile",
        // withCredentials: true,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const userInfoData = profileResponse.data;
      setUserInfo(userInfoData);
      // console.log(userInfoData);
      navigate("/myprofile");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        displayError("Invalid email or password. Please try again.");
      } else {
        displayError("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="signufform">
      <div
        className="loading-overlay"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={isLoading}
        />
        <p>Loading...</p>
      </div>
      <div
        className="sign_form"
        style={{ filter: isLoading ? "blur(3px)" : "none" }}
      >
        <form className="post_sign" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form_input form_inp"
              placeholder={"Your email"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="form_input form_inp"
                placeholder={"Enter your password here"}
              />
              <button
                className="showbutton"
                type="button"
                onClick={handleTogglePassword}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <button className="sign_bt" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>
        <div className="already1">
          Don't have an account?{" "}
          <Link to="/signup" className="signalt">
            Sign up
          </Link>
        </div>
        <div className="already1">
          Forgot password?{" "}
          <Link to="/forgot" className="signalt">
            Click here to reset password
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignILForm;
