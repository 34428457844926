import { useLocation, Link } from "react-router-dom";

import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { AiOutlineCar } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";

import "../../styles/mfooter.css";

export default function FMFooter() {
  const location = useLocation();

  const isAuthenticated = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return !!userInfo && userInfo.username;
  };

  return (
    <div className="foot">
      <Link
        className={`eachFoot ${
          location.pathname === "/stores" ? "selected" : ""
        }`}
        to="/stores"
      >
        <MdStoreMallDirectory size={25} />
        <p className={` ${location.pathname === "/company" ? "selected" : ""}`}>
          Stores
        </p>
      </Link>
      <Link
        className={`eachFoot ${
          location.pathname === "/company" ? "selected" : ""
        }`}
        to="/stores"
      >
        <GoOrganization size={25} />
        <p className={` ${location.pathname === "/company" ? "selected" : ""}`}>
          Whole sellers
        </p>
      </Link>
      <Link
        className={`eachFoot ${
          location.pathname === "/logistics" ? "selected" : ""
        }`}
        to="/logistics"
      >
        <AiOutlineCar size={25} />
        <p className={` ${location.pathname === "/company" ? "selected" : ""}`}>
          Logistics
        </p>
      </Link>
      <Link
        className={`eachFoot ${
          location.pathname === "/farmersmarket" ? "selected" : ""
        }`}
        to="/farmersmarket"
      >
        <MdAgriculture size={25} />
        <p className={` ${location.pathname === "/company" ? "selected" : ""}`}>
          Farmer's
        </p>
      </Link>
      {isAuthenticated() && (
        <Link
          className={`eachFoot ${
            location.pathname === "/myprofile" ? "selected" : ""
          }`}
          to="/myprofile"
        >
          <BsPerson size={25} />
          <p
            className={` ${location.pathname === "/company" ? "selected" : ""}`}
          >
            Profile
          </p>
        </Link>
      )}
    </div>
  );
}
