import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { BiArrowBack } from "react-icons/bi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import "../../styles/waitlist.css";
import Navbar from "../Navbar";

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: "toast-top-right",
  timeOut: 5000,
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

const notify = () => {
  toastr.success(
    "Withdrawal Successful, Your money will be in your bank account in less than 30 minutes.",
    "Success Message"
  );
};

const displayError1 = () => {
  toastr.warning("Withdrawal Failed.", "Try again later");
};

function Withdrawal() {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  const [totalAmount, setTotalAmount] = useState("");

  const handleInputChange1 = (e) => {
    const inputText = e.target.value;
    const numericText = inputText.replace(/\D/g, ""); // Remove non-numeric characters
    setTotalAmount(numericText);
  };

  useEffect(() => {
    if (userInfo.logisticsName) {
      setUserType("logistics");
    } else if (userInfo.storeName) {
      setUserType("store");
    } else if (userInfo.companyName) {
      setUserType("company");
    } else if (userInfo.farmName) {
      setUserType("farm");
    } else {
      setUserType("user");
    }
  }, [userInfo]);


  const makeWithdrawal = async () => {
    try {
      const response = await axios.post(
        "/api/v1/general/withdraw",
        { amount: totalAmount },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(response.data);
      notify(response.data.message);
      const profileResponse = await axios({
        url: `api/v1/${userType}/profile`,
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        method: "GET",
      });
      const userInfoData = profileResponse.data;
      setUserInfo(userInfoData);
      // setIsLoading(false);
      navigate("/myprofile");
    } catch (error) {
      displayError1();
      console.error("Error making withdrawal: ", error);
      // Handle the error, e.g., showing a message to the user
    }
  };

  useEffect(() => {
    // Check if bank details exist, if not redirect to input bank details component
    if (!userInfo.bankCode) {
      navigate("/addaccount"); // Redirect to the component for inputting bank details
    }
  }, [userInfo, navigate]);

  return (
    <div>
      <Navbar />
      <div className="container1">
        <div className="goBack">
          <button onClick={() => navigate(-1)} className="goBack">
            <BiArrowBack /> Go back
          </button>
        </div>
        <br />
        <div className="post_blog">
          <div className="waitlist_post">
            <label htmlFor="amount" className="form_label">
              Amount
            </label>
            <input
              type="text"
              id="amount"
              name="amount"
              onChange={handleInputChange1}
              value={totalAmount}
              className="form_input"
              placeholder="How much do you intend to Withdraw?"
            />
          </div>
          <button onClick={makeWithdrawal} className="order-details-btn2">
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
}

export default Withdrawal;
