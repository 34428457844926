import React from "react";

const DownloadButton = ({ icon, title1, title2 }) => {
  return (
    <button className="download-button">
      <img
        src={icon}
        alt={`${title2} icon`}
        className="download-button__icon"
      />
      <div className="download-button__text">
        <p className="download-button__title1">{title1}</p>
        <p className="download-button__title2">
          <b>{title2}</b>
        </p>
      </div>
    </button>
  );
};

export default DownloadButton;
