import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../styles/cart.css";
import { BsCart4 } from "react-icons/bs";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { PaystackButton } from "react-paystack";
import { PUBLIC_KEY } from "../../config";
import { UserContext } from "../../context/UserContext";

const StoreCart = ({ storeCartItems }) => {
  const [storeCart, setStoreCart] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState("");
  const [pickupLocations, setPickupLocations] = useState([]);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [logisticsVehicles, setLogisticsVehicles] = useState([]);
  const [selectedLogisticsVehicle, setSelectedLogisticsVehicle] = useState("");
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState(null);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    const fetchStoreCartItems = async () => {
      try {
        const response = await axios({
          url: "/api/v1/storecart",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
        });
        setStoreCart(response.data.cart.items);
        setDataLoaded(true);

        const storeLocations = response.data.storeLocations;
        const pickupLocationsData = [];
        storeLocations.forEach((store) => {
          store.locations.forEach((location) => {
            pickupLocationsData.push({
              id: location._id,
              name: location.address,
              address: location.city,
              storeId: store.storeId,
              storeName: store.storeName,
              coordinates: location.coordinates,
            });
          });
        });
        setDeliveryOption(response.data.cart.deliveryOption);
        setDeliveryAddress(response.data.cart.deliveryAddress);
        setSelectedLogisticsVehicle(response.data.cart.logistics);

        if (response.data.cart.logistics) {
          const vehicle = logisticsVehicles.find(
            (vehicle) => vehicle._id === response.data.cart.logistics
          );
          setSelectedVehicleDetails(vehicle);
        }
        setTotalAmount(response.data.totalAmount);
        setPickupLocations(pickupLocationsData);

        setSelectedPickupLocation(response.data.cart.pickupLocation);
        // const selectedVehicle = logisticsVehicles.find(vehicle => vehicle._id === response.data.logistics);
        // setSelectedVehicleDetails(selectedVehicle);
      } catch (error) {
        console.error("Error fetching store cart data:", error);
      }
    };

    fetchStoreCartItems();
  }, [logisticsVehicles, selectedLogisticsVehicle]);

  const createOrderWithWallet = async () => {
    try {
      const orderData = {
        paymentMethod: "wallet", // Replace with your other payment method
      };

      const response = await axios.post(
        "/api/v1/storeorder/create",
        orderData,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log("Order created:", response.data);
      window.location = "/myprofile/OrderList";
      return response.data;
    } catch (error) {
      // console.error("Error creating order with other method:", error);
      // Handle the error from the backend
      if (
        error.response ||
        error.response.data ||
        error.response.data.message
      ) {
        alert(error.response.data.error);
      }
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      const response = await axios.delete("/api/v1/storecart", {
        data: { product: itemId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setStoreCart(response.data.cart.items);
    } catch (error) {
      console.error("Error removing item from the store cart:", error);
    }
  };

  const handleQuantityChange = async (itemId, newQuantity) => {
    try {
      const response = await axios.put(
        "/api/v1/storecart",
        {
          product: itemId,
          quantity: newQuantity,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setStoreCart(response.data.cart.items);

      const updatedTotalAmount = response.data.totalAmount;
      setTotalAmount(updatedTotalAmount);
    } catch (error) {
      console.error("Error updating item quantity in the store cart:", error);
    }
  };

  const handleDeliveryOptionChange = async (option) => {
    setDeliveryOption(option);

    if (option === "pickup") {
      try {
        const response = await axios.put("/api/v1/storecart/pickup", {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setSelectedLogisticsVehicle();
        const updatedTotalAmount = response.data.totalAmount;
        setTotalAmount(updatedTotalAmount);
      } catch (error) {
        console.error("Error calling pickup API:", error);
      }
    }
  };

  const handleSelectPickupLocation = async (locationId, storeId) => {
    try {
      setSelectedPickupLocation(locationId);

      await axios.put(
        "/api/v1/storecart/addlocation",
        {
          storeLocationId: locationId,
          storeId: storeId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error setting pickup location:", error);
    }
  };

  const handleSearchLogistics = async () => {
    try {
      const selectedLocation = pickupLocations.find(
        (location) => location.id === selectedPickupLocation
      );

      if (selectedLocation) {
        const [longitude, latitude] = selectedLocation.coordinates.coordinates;

        const response = await axios.put(
          "/api/v1/logisticsvehicle/cartVehicles",
          {
            latitude,
            longitude,
          },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setLogisticsVehicles(response.data.logisticsVehicles);
      }
    } catch (error) {
      console.error("Error fetching logistics companies:", error);
    }
  };

  const handleSelectLogisticsVehicle = async (vehicleId) => {
    setSelectedLogisticsVehicle(vehicleId);
    const selectedVehicle = logisticsVehicles.find(
      (vehicle) => vehicle._id === vehicleId
    );
    setSelectedVehicleDetails(selectedVehicle);
    const response = await axios.put(
      "/api/v1/storecart/addvehicle",
      {
        logistics: vehicleId,
        deliveryAddress: deliveryAddress,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setSelectedLogisticsVehicle(response.data.logistics);
  };

  // const handleLogisticsBooking = async () => {
  //   try {
  //     const response = await axios.post("/api/v1/logisticsbooking", {
  //       pickupLocation: selectedPickupLocation,
  //       deliveryOption,
  //       logisticsVehicle: selectedLogisticsVehicle,
  //       cartItems: storeCart,
  //     });

  //     console.log("Logistics Booking Response:", response.data);

  //   } catch (error) {
  //     console.error("Error during logistics booking:", error);
  //   }
  // };

  const handleAddressChange = async (newAddress) => {
    try {
      setDeliveryAddress(newAddress);

      if (selectedLogisticsVehicle) {
        const response = await axios.put(
          "/api/v1/storecart/addvehicle",
          {
            logistics: selectedLogisticsVehicle,
            deliveryAddress: newAddress,
          },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSelectedLogisticsVehicle(response.data.logistics);
      }
    } catch (error) {
      console.error("Error updating address and vehicle:", error);
    }
  };

  // const publicKey = "pk_test_b16f775a67c780753bae2872a4041c3b56eb03d5";
  // const publicKey = "pk_live_712332318f9883cc97fda53fda3b545fddd78bfa";

  const config = {
    reference: new Date().getTime().toString(),
    email: userInfo.email,
    amount: totalAmount * 100,
    publicKey: PUBLIC_KEY,
  };

  const onSuccess = (reference) => {
    try {
      console.log("Payment reference:", reference);
      // Call createOrder function and handle its response
      createOrder()
        .then((orderData) => {
          // If order creation is successful, navigate to the order page
          // console.log("Order created:", orderData);
          window.location = "/myprofile/OrderList";
        })
        .catch((error) => {
          console.error("Error handling successful payment:", error);
          // Handle the error, e.g., showing a message to the user
        });
    } catch (error) {
      console.error("Error handling successful payment:", error);
      // Handle the error, e.g., showing a message to the user
    }
  };

  const onClose = () => {
    console.log("Payment closed");

    window.location = "/myprofile/MyCart";
  };

  const componentProps = {
    ...config,
    text: "Pay with Bank",
    onSuccess: onSuccess,
    onClose: onClose,
  };

  const createOrder = async () => {
    try {
      const orderData = {};

      const response = await axios.post(
        "/api/v1/storeorder/create",
        orderData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log("Order created:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (selectedPickupLocation) {
      handleSearchLogistics();
    }
  }, [selectedPickupLocation]);

  return (
    <div>
      {dataLoaded ? (
        storeCart.length === 0 ? (
          <div>
            <BsCart4 size={200} />
            <p>
              You've not picked any product into your cart from any nearby
              stores.
            </p>
          </div>
        ) : (
          <div className="detailCart1">
            <table>
              <thead>
                <tr>
                  <th colSpan="2" className="detailCartEach1">
                    Product Details
                  </th>
                  <th className="detailCartEach">Quantity</th>
                  <th className="detailCartEach">Subtotal</th>
                  <th className="detailCartEach">Action</th>
                </tr>
              </thead>
              <tbody>
                {storeCart.map((item) => (
                  <tr key={item._id}>
                    <td className="detailCartEach1">
                      <img
                        src={item.product.images[0]}
                        alt={item.product.productName}
                        className="otherImages"
                      />
                    </td>
                    <td className="detailCartEach1">
                      <h4>{item.product.productName}</h4>
                    </td>
                    <td className="CartQuantityCont">
                      <button
                        onClick={() =>
                          handleQuantityChange(
                            item.product._id,
                            item.quantity - 1
                          )
                        }
                      >
                        <FaLessThan />
                      </button>
                      {item.quantity}
                      <button
                        onClick={() =>
                          handleQuantityChange(
                            item.product._id,
                            item.quantity + 1
                          )
                        }
                      >
                        <FaGreaterThan />
                      </button>
                    </td>
                    <td className="detailCartEach1">
                      &#x20A6;{item.quantity * item.product.price}
                    </td>
                    <td className="detailCartEach">
                      <div onClick={() => handleRemoveItem(item.product._id)}>
                        <MdDelete size="20" className="cartBasket" />
                        Remove item
                      </div>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3" className="detailCartEach1">
                    Total
                  </td>
                  <td colSpan="2" className="detailCartEach1">
                    &#x20A6;
                    {totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      ) : (
        <p>Loading cart data...</p>
      )}

      <div className="cartLogCont">
        <div>
          <label className="deliveryOption">
            Delivery Option:
            <select
              value={deliveryOption}
              className="options"
              onChange={(e) => handleDeliveryOptionChange(e.target.value)}
            >
              <option value="pickup">Pickup myself</option>
              <option value="delivery">Delivery to me</option>
            </select>
          </label>
        </div>

        <div>
          <label className="deliveryOption">
            Pickup Location:
            <select
              value={selectedPickupLocation || ""}
              className="options"
              onChange={(e) => {
                const selectedOption = e.target.value.split("_"); // Splitting the value to extract locationId and storeId
                handleSelectPickupLocation(
                  selectedOption[0],
                  selectedOption[1]
                );
              }}
            >
              <option value="">
                {selectedPickupLocation
                  ? pickupLocations.find(
                      (location) => location.id === selectedPickupLocation
                    )?.name +
                    " - " +
                    pickupLocations.find(
                      (location) => location.id === selectedPickupLocation
                    )?.address
                  : "Select store location"}
              </option>
              {pickupLocations.map((location) => (
                <option
                  key={location.id}
                  value={`${location.id}_${location.storeId}`}
                >
                  {location.name} - {location.address}
                </option>
              ))}
            </select>
          </label>
        </div>

        {deliveryOption === "delivery" && (
          <div>
            <label className="deliveryOption">
              Enter delivery Address:
              <input
                type="text"
                onChange={(e) => handleAddressChange(e.target.value)}
                value={deliveryAddress}
                className="options"
                placeholder={"Enter the address we are to deliver to"}
              />
            </label>
            {/* <button onClick={handleSearchLogistics}>Search Logistics</button> */}
            <label className="deliveryOption">
              Select Logistics Vehicle:
              <select
                value={selectedLogisticsVehicle}
                className="options"
                onChange={(e) => handleSelectLogisticsVehicle(e.target.value)}
              >
                <option value="">Select Logistics Vehicle</option>
                {logisticsVehicles.map((vehicle) => (
                  <option key={vehicle._id} value={vehicle._id}>
                    {vehicle.vehicleType} - {vehicle.plateNum} - {vehicle.price}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}

        {selectedVehicleDetails && (
          <div className="selectedVehicleDetails">
            <h5>Selected Vehicle Details:</h5>
            <div className="selectedVehicle">
              <img src={selectedVehicleDetails.image} alt="Vehicle" />
              <div>
                <p>Vehicle Type: {selectedVehicleDetails.vehicleType}</p>
                <p>Plate Number: {selectedVehicleDetails.plateNum}</p>
                <p>Price: {selectedVehicleDetails.price}</p>
              </div>
            </div>
          </div>
        )}
        <div className="order-details-btns">
          {/* <PaystackButton {...componentProps} className="order-details-btn2" /> */}
          <button
            className="order-details-btn3"
            onClick={() => createOrderWithWallet()}
          >
            Pay with Wallet
          </button>
          <PaystackButton {...componentProps} className="order-details-btn2" />
        </div>
      </div>
    </div>
  );
};

export default StoreCart;
