import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

import { UserContext } from "../context/UserContext";
import profback from "../static/back.jpg";
import "../styles/profile.css";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ProfileProducts from "../Components/profile/ProfileProducts";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer/Footer";
import FMFooter from "../Components/footer/FMFooter";

function MyProfile() {
  const { contentType } = useParams();
  const [currentContentType, setCurrentContentType] = useState(
    contentType || "Dashboard"
  );
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentContentType(contentType);
  }, [contentType]);

  const { userInfo } = useContext(UserContext);

  console.log("user info ==> ", userInfo);
  let x = null;

  useEffect(() => {
    if (!userInfo?._id) {
      return navigate("/signin");
    }
  });

  if (userInfo) {
    if (userInfo.farmName) {
      x = "farm";
    }
    if (userInfo.logisticsName) {
      x = "logistics";
    }
    if (userInfo.companyName) {
      x = "company";
    }
    if (userInfo.storeName) {
      x = "store";
    }
    if (userInfo.name) {
      x = "user";
    }
  }

  function handleContentChange(type) {
    setCurrentContentType(type);
  }

  function logout() {
    localStorage.removeItem("userInfo");
    axios({
      url: `api/v1/${x}/logout`,
      // withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      method: "POST",
    })
      .then(() => {
        localStorage.removeItem("userInfo");
        // Redirect to the login page after successful logout
        window.location.href = "/signin";
      })
      .catch((error) => {
        console.error(error);
        // Handle error if necessary
      });
  }

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };

  return (
    <div>
      <Navbar />

      <div className="profile">
        <div className="profilemenu">
          {userInfo && ( // Check if userInfo is defined
            <>
              <img
                src={userInfo.coverPhoto ? userInfo?.coverPhoto : profback}
                alt="profile cover"
                className="profback"
              />
              <img
                src={userInfo.avatar ? userInfo.avatar : profback}
                alt="profile "
                className="profimg"
              />
              <div className="profName">
                {userInfo.farmName && (
                  <p className="profName">{userInfo.farmName}</p>
                )}
                {userInfo.storeName && (
                  <p className="profName">{userInfo.storeName}</p>
                )}
                {userInfo.companyName && (
                  <p className="profName">{userInfo.companyName}</p>
                )}
                {userInfo.logisticsName && (
                  <p className="profName">{userInfo.logisticsName}</p>
                )}
                <p className="profName">
                  {userInfo.username ? "@" + userInfo.username : ""}
                </p>

                <div className="manuopt"></div>
                {userInfo.farmName && (
                  <div>
                    <Link onClick={() => handleContentChange("Dashboard")}>
                      <p className="manuopt">Dashboard</p>
                    </Link>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyProducts")}>
                        <p className="manuopt1">My Products</p>
                      </Link>
                      <Link to="/myprofile/farmOrder">
                        <p className="manuopt1">Orders</p>
                      </Link>
                      <Link to="/unavailables">
                        <p className="manuopt1">Requested Products</p>
                      </Link>
                      <Link onClick={() => handleContentChange("Notification")}>
                        <p className="manuopt1">Notifications</p>
                      </Link>
                      <Link to="/chat">
                        <p className="manuopt1">Chat</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>

                      {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                      {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                      {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                    </div>
                    <p className="manuopt">My Purchases</p>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyCart")}>
                        <p className="manuopt1">My Cart</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("FarmOrderList")}
                      >
                        <p className="manuopt1">Farm Orders</p>
                      </Link>
                      <Link onClick={() => handleContentChange("OrderList")}>
                        <p className="manuopt1">Store Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyOrderList")}
                      >
                        <p className="manuopt1">Company Orders</p>
                      </Link>
                      <Link
                        onClick={() =>
                          handleContentChange("logisticsOrderList")
                        }
                      >
                        <p className="manuopt1">Logistics Orders</p>
                      </Link>
                    </div>
                  </div>
                )}
                {userInfo.storeName && (
                  <div>
                    <Link onClick={() => handleContentChange("Dashboard")}>
                      <p className="manuopt">Dashboard</p>
                    </Link>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyProducts")}>
                        <p className="manuopt1">My Products</p>
                      </Link>
                      <Link onClick={() => handleContentChange("AddLocation")}>
                        <p className="manuopt1">Store Locations</p>
                      </Link>
                      <Link onClick={() => handleContentChange("storeOrder")}>
                        <p className="manuopt1">Orders</p>
                      </Link>
                      <Link to="/uploadstoreproduct">
                        <p className="manuopt1">Upload new Product</p>
                      </Link>
                      <Link to="/chat">
                        <p className="manuopt1">Chat</p>
                      </Link>
                      <Link onClick={() => handleContentChange("Notification")}>
                        <p className="manuopt1">Notifications</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>

                      {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                    </div>
                    <p className="manuopt">My Purchases</p>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyCart")}>
                        <p className="manuopt1">My Cart</p>
                      </Link>
                      <Link onClick={() => handleContentChange("OrderList")}>
                        <p className="manuopt1">Store Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyOrderList")}
                      >
                        <p className="manuopt1">Company Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("FarmOrderList")}
                      >
                        <p className="manuopt1">Farm Orders</p>
                      </Link>
                      <Link
                        onClick={() =>
                          handleContentChange("logisticsOrderList")
                        }
                      >
                        <p className="manuopt1">Logistics Orders</p>
                      </Link>
                    </div>

                    <Link onClick={() => handleContentChange("edit")}>
                      <p className="manuopt">Edit Profile</p>
                    </Link>
                  </div>
                )}

                {userInfo.companyName && (
                  <div>
                    <Link onClick={() => handleContentChange("Dashboard")}>
                      <p className="manuopt">Dashboard</p>
                    </Link>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyProducts")}>
                        <p className="manuopt1">My Products</p>
                      </Link>
                      <Link onClick={() => handleContentChange("AddLocation")}>
                        <p className="manuopt1">Company Locations</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyCartList")}
                      >
                        <p className="manuopt1">Carts</p>
                      </Link>
                      <Link onClick={() => handleContentChange("CompanyOrder")}>
                        <p className="manuopt1">Orders</p>
                      </Link>
                      <Link to="/uploadcompanyproduct">
                        <p className="manuopt1">Upload new Product</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>
                      <Link to="/chat">
                        <p className="manuopt1">Chat</p>
                      </Link>
                      <Link onClick={() => handleContentChange("Notification")}>
                        <p className="manuopt1">Notifications</p>
                      </Link>
                      {/* <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link> */}
                      {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                    </div>
                    <p className="manuopt">My Purchases</p>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyCart")}>
                        <p className="manuopt1">My Cart</p>
                      </Link>
                      <Link onClick={() => handleContentChange("OrderList")}>
                        <p className="manuopt1">Store Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyOrderList")}
                      >
                        <p className="manuopt1">Company Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("FarmOrderList")}
                      >
                        <p className="manuopt1">Farm Orders</p>
                      </Link>
                      <Link
                        onClick={() =>
                          handleContentChange("logisticsOrderList")
                        }
                      >
                        <p className="manuopt1">Logistics Orders</p>
                      </Link>
                    </div>

                    <Link onClick={() => handleContentChange("edit")}>
                      <p className="manuopt">Edit Profile</p>
                    </Link>
                  </div>
                )}

                {userInfo.logisticsName && (
                  <div>
                    <Link onClick={() => handleContentChange("Dashboard")}>
                      <p className="manuopt">Dashboard</p>
                    </Link>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyVehicles")}>
                        <p className="manuopt1">My Vehicles</p>
                      </Link>
                      <Link onClick={() => handleContentChange("AddLocation")}>
                        <p className="manuopt1">Logistics Locations</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("logisticsOrder")}
                      >
                        <p className="manuopt1">Store Bookings</p>
                      </Link>
                      <Link onClick={() => handleContentChange("directOrder")}>
                        <p className="manuopt1">Direct Bookings</p>
                      </Link>
                      <Link to="/chat">
                        <p className="manuopt1">Chat</p>
                      </Link>
                      <Link onClick={() => handleContentChange("Notification")}>
                        <p className="manuopt1">Notifications</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>
                      {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                      {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                      {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                    </div>
                    <p className="manuopt">My Purchases</p>
                    <div className="subopt">
                      <Link onClick={() => handleContentChange("MyCart")}>
                        <p className="manuopt1">My Cart</p>
                      </Link>
                      <Link onClick={() => handleContentChange("OrderList")}>
                        <p className="manuopt1">Store Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyOrderList")}
                      >
                        <p className="manuopt1">Company Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("FarmOrderList")}
                      >
                        <p className="manuopt1">Farm Orders</p>
                      </Link>
                      <Link
                        onClick={() =>
                          handleContentChange("logisticsOrderList")
                        }
                      >
                        <p className="manuopt1">Logistics Orders</p>
                      </Link>
                    </div>
                    <Link onClick={() => handleContentChange("edit")}>
                      <p className="manuopt">Edit Profile</p>
                    </Link>
                  </div>
                )}
                {userInfo.name && (
                  <div>
                    <Link onClick={() => handleContentChange("Dashboard")}>
                      <p className="manuopt">Dashboard</p>
                    </Link>
                    <div className="subopt">
                      {/* <Link>
                        <p className="manuopt1">My Products</p>
                      </Link> */}
                      <Link onClick={() => handleContentChange("MyCart")}>
                        <p className="manuopt1">My Cart</p>
                      </Link>
                      <Link onClick={() => handleContentChange("OrderList")}>
                        <p className="manuopt1">Store Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("CompanyOrderList")}
                      >
                        <p className="manuopt1">Company Orders</p>
                      </Link>
                      <Link
                        onClick={() => handleContentChange("FarmOrderList")}
                      >
                        <p className="manuopt1">Farm Orders</p>
                      </Link>
                      <Link
                        onClick={() =>
                          handleContentChange("logisticsOrderList")
                        }
                      >
                        <p className="manuopt1">Logistics Orders</p>
                      </Link>
                      <Link to="/chat">
                        <p className="manuopt1">Chat</p>
                      </Link>
                      <Link onClick={() => handleContentChange("Notification")}>
                        <p className="manuopt1">Notifications</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>
                      {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                      {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                      {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                      <Link onClick={notify}>
                        <p className="manuopt1">Wallet</p>
                      </Link>
                    </div>
                  </div>
                )}

                {/* <Link onClick={notify}>
                  <p className="manuopt">Profile Settings</p>
                </Link> */}
                <Link to="/tandc">
                  <p className="manuopt1">Terms and Condition</p>
                </Link>
                <Link to="/return">
                  <p className="manuopt1">Return Policy</p>
                </Link>
                <Link to="/faqs">
                  <p className="manuopt1">Frequently asked questions (FAQs)</p>
                </Link>
                <Link to="/referral">
                  <p className="manuopt1">Referral Terms and Benefits</p>
                </Link>
                <Link onClick={logout} className="menuitemhere" to="/">
                  <p className="manuoptlog">Logout</p>
                  <BiLogOut />
                </Link>
              </div>
            </>
          )}
        </div>
        {/* <ProfileProducts currentContentType={!currentContentType ? "Dashboard" : currentContentType} /> */}
        <ProfileProducts
          currentContentType={
            !currentContentType ? "Dashboard" : currentContentType
          }
          handleContentChange={handleContentChange}
        />
      </div>

      <div>
        {/* Other components or JSX */}
        {/* <OrderList handleContentChange={handleContentChange} /> */}
        {/* Other components or JSX */}
      </div>
      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default MyProfile;
