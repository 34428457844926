import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import profback from "../../static/back.jpg";
import Footer from "../../Components/footer/Footer";
import { FaStar, FaRegStar } from "react-icons/fa";
import { CiShoppingBasket } from "react-icons/ci";
import { HeadProvider, Meta } from "react-head";

const StoreProducts = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [storeData, setStoreData] = useState(null);
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        const response = await axios.get(`/api/v1/storeproducts/${slug}`);
        const {
          storeName,
          storeAddress,
          avatar,
          city,
          username,
          categories,
          products,
          pages,
        } = response.data;

        setStoreData({
          storeName,
          storeAddress,
          avatar,
          city,
          username,
          categories,
        });
        setProducts(products);
        setTotalPages(pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        setIsLoading(false);
      }
    };

    fetchStoreData();
  }, [slug]);

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`/api/v1/storeproducts/${slug}`, {
        params: {
          pageNumber: page,
          keyword,
          categories: selectedCategories,
        },
      });

      const newProducts = response.data.products;
      if (page === 1) {
        // Replace products when resetting the page (filters or search changed)
        setProducts(newProducts);
      } else {
        // Append products when "Load More" is clicked
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
      }
      setTotalPages(response.data.pages);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsLoading(false);
    }
  };

  // Refactor: Watch searchTerm changes and trigger filtering
  useEffect(() => {
    fetchProducts();
  }, [page, keyword, selectedCategories, slug]);

  const loadMore = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleSearch = (event) => {
    setKeyword(event.target.value);
    setPage(1);
  };

  const handleCategorySelect = (categoryId) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((id) => id !== categoryId)
      );
    } else {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        categoryId,
      ]);
    }
    setPage(1);
  };

  const rating =
    products.numReviews > 0 ? (products.numReviews / 5).toFixed(1) : "0.0";
  // let number = products;
  // let x = Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
  //   number
  // );

  return (
    <div>
      <Navbar />
      <div className="storSearchField">
        <input
          type="text"
          placeholder="Search products by name"
          value={keyword}
          onChange={handleSearch}
          className="searchField"
        />
      </div>

      <HeadProvider>
        {storeData && (
          <>
            <Meta charSet="utf-8" />
            <Meta
              name="description"
              content={`Welcome to ${storeData.storeName}`}
            />
            <Meta property="og:title" content={storeData.storeName} />
            <Meta property="og:image" content={storeData.avatar || profback} />
            <Meta
              property="og:description"
              content={`Welcome to ${storeData.storeName}`}
            />
          </>
        )}
      </HeadProvider>
      {storeData && (
        <img
          src={storeData.avatar ? storeData.avatar : profback}
          alt={storeData.storeName}
          className="profback"
        />
      )}

      {isLoading ? (
        // Display a loading indicator while data is loading
        <div className="loading">Loading...</div>
      ) : (
        <div>
          <div>
            {storeData && (
              <div className="storeInfo">
                <img
                  src={storeData.avatar ? storeData.avatar : profback}
                  alt={storeData.storeName}
                  className="storeAvatar"
                />
                <div className="storeInfo1">
                  <h2>{storeData.storeName}</h2>
                  {/* <p>{storeData.storeAddress}</p> */}
                  {/* <p>{storeData.city}</p> */}
                  <p>{storeData.username}</p>
                </div>
              </div>
            )}

            <div className="backgroundContainer">
              <div>
                {storeData &&
                  storeData.categories.map((category) => (
                    <button
                      key={category._id}
                      onClick={() => handleCategorySelect(category._id)}
                      className={`categoryButton ${
                        selectedCategories.includes(category._id)
                          ? "selected"
                          : ""
                      }`}
                    >
                      {category.name}
                    </button>
                  ))}
              </div>
              <div className="listProducts">
                {products.map((product) => (
                  <Link
                    key={product._id}
                    className="eachproduct"
                    to={`/store/${slug}/${product.slug}`}
                  >
                    <img
                      src={
                        product.images[0] ? product.images[0] : storeData.avatar
                      }
                      alt={product.productName}
                      className="productImage"
                    />
                    {storeData?.storeName && (
                      <p className="farmname">
                        {storeData.storeName.length > 17
                          ? `${storeData.storeName.substring(0, 13)}...`
                          : storeData.storeName}
                      </p>
                    )}
                    <p className="productname">
                      {product.productName.length > 13
                        ? `${product.productName.substring(0, 13)}...`
                        : product.productName}
                    </p>
                    {/* <p>{product.productDescription}</p> */}
                    <div className="ratingc">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <span key={index}>
                          {index < Math.floor(rating) ? (
                            <FaStar />
                          ) : (
                            <FaRegStar />
                          )}
                        </span>
                      ))}
                      <span className="rating"> {rating} stars</span>
                    </div>
                    <p className="scale">
                      &#x20A6;{product.price} per{" "}
                      {product.measuringScale.length > 13
                        ? `${product.measuringScale.substring(0, 8)}...`
                        : product.measuringScale}
                    </p>
                    <div className="addToCart">
                      <CiShoppingBasket size="16px" />
                      <span className="scale"> {"  "}Add To Cart</span>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {page < totalPages && (
        <button onClick={loadMore} className="loadMoreButton">
          {isLoading ? "Loading..." : "Load More"}
        </button>
      )}
      <Footer />
    </div>
  );
};

export default StoreProducts;
