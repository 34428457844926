import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "../../styles/EditLogistics.css"; // Shared CSS file

const daysOfWeek = [
  { name: "Monday", id: "monday" },
  { name: "Tuesday", id: "tuesday" },
  { name: "Wednesday", id: "wednesday" },
  { name: "Thursday", id: "thursday" },
  { name: "Friday", id: "friday" },
  { name: "Saturday", id: "saturday" },
  { name: "Sunday", id: "sunday" },
];

const EditLogistics = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [logisticsData, setLogisticsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [logisticsHours, setLogisticsHours] = useState([]);
  const [isLogisticsClosed, setIsLogisticsClosed] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  useEffect(() => {
    const fetchLogisticsData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/api/v1/logistics/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setLogisticsData(response.data);
        setIsLogisticsClosed(response.data.closed);
        setLogisticsHours(response.data.schedule || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching logistics data:", error);
        toastr.error("Failed to load logistics data. Please try again.");
        setIsLoading(false);
      }
    };

    fetchLogisticsData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("logisticsName", logisticsData.logisticsName);
      formData.append("logisticsAddress", logisticsData.logisticsAddress);
      formData.append("city", logisticsData.city);
      formData.append("username", logisticsData.username);
      formData.append("phoneNumber", logisticsData.phoneNumber);
      formData.append("email", logisticsData.email);
      formData.append("avatar", avatar ? avatar[0] : logisticsData.avatar);
      formData.append("closed", isLogisticsClosed);
      formData.append("coverPhoto", coverPhoto ? coverPhoto[0] : logisticsData.coverPhoto);
      formData.append("schedule", JSON.stringify(logisticsHours.map(convertTimeTo24Hours)));

      const response = await axios.put("/api/v1/logistics/profile", formData, {
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastr.success("Logistics details updated successfully!");
      setIsLoading(false);
      navigate("/myprofile");
    } catch (error) {
      console.error("Error updating logistics details:", error);
      toastr.error("Failed to update logistics details. Please try again.");
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLogisticsData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    if (type === "avatar") {
      setAvatar(e.target.files);
    } else if (type === "coverPhoto") {
      setCoverPhoto(e.target.files);
    }
  };

  const handleLogisticsHoursChange = (day, field, value) => {
    const updatedLogisticsHours = [...logisticsHours];
    const index = updatedLogisticsHours.findIndex((hour) => hour.day === day);
    if (index !== -1) {
      updatedLogisticsHours[index][field] = value;
    } else {
      updatedLogisticsHours.push({ day, openingTime: "", closingTime: "" });
      updatedLogisticsHours[updatedLogisticsHours.length - 1][field] = value;
    }
    setLogisticsHours(updatedLogisticsHours);
  };

  const convertTimeTo24Hours = (hour) => {
    if (!hour.openingTime || !hour.closingTime) return hour;
    const openingTime24 = new Date(`2000-01-01T${hour.openingTime}`);
    const closingTime24 = new Date(`2000-01-01T${hour.closingTime}`);
    return {
      ...hour,
      openingTime: openingTime24.toTimeString().slice(0, 5),
      closingTime: closingTime24.toTimeString().slice(0, 5),
    };
  };

  return (
    <div className="edit-logistics">
      <div className="loading-overlay" style={{ display: isLoading ? "block" : "none" }}>
        <ClipLoader css={override} size={150} color={"#123abc"} loading={isLoading} />
        <p>Loading...</p>
      </div>
      <form onSubmit={handleSubmit} className="edit-logistics-form">
        <h2>Edit Logistics Profile</h2>
        <div className="form-group">
          <label>Logistics Name</label>
          <input
            type="text"
            name="logisticsName"
            value={logisticsData.logisticsName || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Logistics Address</label>
          <input
            type="text"
            name="logisticsAddress"
            value={logisticsData.logisticsAddress || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={logisticsData.city || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={logisticsData.username || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={logisticsData.phoneNumber || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={logisticsData.email || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Avatar</label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, "avatar")}
            className="custom-file-input"
            accept="image/*"
          />
          {logisticsData.avatar && (
            <div className="image-preview-container">
              <div className="image-preview">
                <img src={logisticsData.avatar} alt="Logistics Avatar" />
              </div>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Cover Photo</label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, "coverPhoto")}
            className="custom-file-input"
            accept="image/*"
          />
          {logisticsData.coverPhoto && (
            <div className="image-preview-container">
              <div className="image-preview">
                <img src={logisticsData.coverPhoto} alt="Logistics Cover Photo" />
              </div>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Logistics Hours</label>
          {daysOfWeek.map((day) => (
            <div key={day.id} className="store-hour">
              <span>{day.name}</span>
              <input
                type="time"
                value={
                  logisticsHours.find((hour) => hour.day === day.name)?.openingTime || ""
                }
                onChange={(e) => handleLogisticsHoursChange(day.name, "openingTime", e.target.value)}
              />
              <input
                type="time"
                value={
                  logisticsHours.find((hour) => hour.day === day.name)?.closingTime || ""
                }
                onChange={(e) => handleLogisticsHoursChange(day.name, "closingTime", e.target.value)}
              />
            </div>
          ))}
        </div>
        <div className="form-group">
          <label>Closed</label>
          <select
            name="closed"
            value={isLogisticsClosed ? "closed" : "open"}
            onChange={(e) => setIsLogisticsClosed(e.target.value === "closed")}
          >
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </select>
        </div>
        <button type="submit" className="submit-button">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditLogistics;
