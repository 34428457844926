import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/rateProducts.css";
import Navbar from "../Navbar";
import Footer from "../footer/Footer";

const RateProducts = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`/api/v1/storeorder/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const order = response.data.order;
      const storeSlug = order.store.slug; // Assuming store slug is part of store data
      const reviewsWithStoreSlug = order.items.map((item) => ({
        ...item,
        storeSlug,
        productSlug: item.product.slug,
        rating: 0,
        text: "",
      }));
      setReviews(reviewsWithStoreSlug);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  const handleRatingChange = (productSlug, rating) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.productSlug === productSlug ? { ...review, rating } : review
      )
    );
  };

  const handleTextChange = (productSlug, text) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.productSlug === productSlug ? { ...review, text } : review
      )
    );
  };

  const handleSubmitAll = async () => {
    try {
      await Promise.all(
        reviews.map((review) =>
          axios.post(
            `/api/v1/storeproducts/${review.storeSlug}/${review.productSlug}`,
            { rating: review.rating, text: review.text },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        )
      );
      alert("All reviews submitted successfully!");
      navigate("/stores/storeSlug"); // Redirect to some other page after submission
    } catch (error) {
      console.error("Error submitting reviews:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="rate-products-container">
        <h2>Rate Products</h2>
        {reviews.map((review) => (
          <div key={review.productSlug} className="rate-product">
            <div className="product-info">
              <img
                src={review.product.images[0]}
                alt={review.product.productName}
              />
              <div className="product-details">
                <h3>{review.product.productName}</h3>
                <div className="star-rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={review.rating >= star ? "star filled" : "star"}
                      onClick={() =>
                        handleRatingChange(review.productSlug, star)
                      }
                    >
                      ★
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="rating-section">
              <textarea
                placeholder="Leave a review"
                value={review.text}
                onChange={(e) =>
                  handleTextChange(review.productSlug, e.target.value)
                }
              />
            </div>
          </div>
        ))}
        <button onClick={handleSubmitAll} className="order-details-btn3">
          Submit All Reviews
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default RateProducts;
