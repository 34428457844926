import React from "react";
// import Navbar from "../Components/Navbar";
// import Footer from "../Components/Footer";
import LogisticsOrders from "../../Components/logisticCom/LogisticsOrders";
// import LogisticsBook from "../Components/LogisticsBook";

import '../../styles/logistics.css'

export default function LogisticsPage() {
  return (
    <div>
      {/* <Navbar /> */}
      {/* <div className="logistics"> */}
        <LogisticsOrders />
        {/* <LogisticsBook /> */}
      {/* </div> */}
      {/* <Footer/> */}
    </div>
  );
}
