import { useState } from "react";
import { Link } from "react-router-dom";

import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { BsPerson } from "react-icons/bs";
import { AiOutlineCar } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";

import "../styles/signup.css";

import logo from "../static/FARMY EMB green..png";

import RecoverAccountF from "../Components/recovery/RecoverAccountF";
import RecoverAccountC from "../Components/recovery/RecoverAccountC";
import RecoverAccountS from "../Components/recovery/RecoverAccountS";
import RecoverAccountL from "../Components/recovery/RecoverAccountL";
import RecoverAccountCo from "../Components/recovery/RecoverAccountCo";

export default function RecoverAccount() {
  const [selectedButton, setSelectedButton] = useState("farm");

  const handleButtonSelect = (buttonId) => {
    setSelectedButton(buttonId);
  };

  return (
    <div className="signContainer">
      <div className="signutarget">
        <Link
          onClick={() => handleButtonSelect("customer")}
          className="signueach"
        >
          <div className="signueach1">
            <div>
              <BsPerson />
            </div>
            <div>Recover Account as a Buyer</div>
            <div>
              {selectedButton === "customer" && <IoIosCheckmarkCircle />}
            </div>
          </div>
        </Link>
        <Link onClick={() => handleButtonSelect("store")} className="signueach">
          <div className="signueach1">
            <div>
              <MdStoreMallDirectory />
            </div>
            <div>Recover Account as a Store Owner</div>
            <div>{selectedButton === "store" && <IoIosCheckmarkCircle />}</div>
          </div>
        </Link>
        <Link onClick={() => handleButtonSelect("farm")} className="signueach">
          <div className="signueach1">
            <div>
              <MdAgriculture />
            </div>
            <div>Recover Account as a Farmer</div>
            <div>{selectedButton === "farm" && <IoIosCheckmarkCircle />}</div>
          </div>
        </Link>
        <Link
          onClick={() => handleButtonSelect("Logistics")}
          className="signueach"
        >
          <div className="signueach1">
            <div>
              <AiOutlineCar />
            </div>
            <div>Recover Account as a Logistics</div>
            <div>
              {selectedButton === "Logistics" && <IoIosCheckmarkCircle />}
            </div>
          </div>
        </Link>
        <Link
          onClick={() => handleButtonSelect("company")}
          className="signueach"
        >
          <div className="signueach1">
            <div>
              <GoOrganization />
            </div>
            <div>Recover Account as a Wholesaler</div>
            <div>
              {selectedButton === "company" && <IoIosCheckmarkCircle />}
            </div>
          </div>
        </Link>
        <div className="already">
          Don't have an account?
          <span> </span>
          <Link to="/signup" className="signlink">
            Sign Up
          </Link>
        </div>
      </div>
      <div className="signufform">
        <img src={logo} alt="FarmyApp logo" className="lohosm" />
        <div>Welcome to FarmyApp</div>
        <div>
          <div className="signtext">Recover Account as</div>
          <div className="targets">
            <div className="signutargetm">
              <Link
                onClick={() => handleButtonSelect("customer")}
                className="signueachm"
              >
                <div
                  className={`toggle-button ${
                    selectedButton === "customer"
                      ? "signueach1ms"
                      : "signueach1m"
                  }`}
                >
                  <div className="targetIcons">
                    <BsPerson />
                  </div>
                  <div className="targetText">
                    <div>Buyer</div>
                  </div>
                </div>
              </Link>
              <Link
                onClick={() => handleButtonSelect("store")}
                className="signueachm"
              >
                <div
                  className={`toggle-button ${
                    selectedButton === "store" ? "signueach1ms" : "signueach1m"
                  }`}
                >
                  <div className="targetIcons">
                    <MdStoreMallDirectory />
                  </div>
                  <div className="targetText">
                    <div>Store Owner</div>
                  </div>
                </div>
              </Link>
              <Link
                onClick={() => handleButtonSelect("company")}
                className="signueachm"
              >
                <div
                  className={`toggle-button ${
                    selectedButton === "company"
                      ? "signueach1ms"
                      : "signueach1m"
                  }`}
                >
                  <div className="targetIcons">
                    <GoOrganization />
                  </div>
                  <div className="targetText">
                    <div>Wholesaler</div>
                  </div>
                </div>
              </Link>
              <Link
                onClick={() => handleButtonSelect("farm")}
                className="signueachm"
              >
                <div
                  className={`toggle-button ${
                    selectedButton === "farm" ? "signueach1ms" : "signueach1m"
                  }`}
                >
                  <div className="targetIcons">
                    <MdAgriculture />
                  </div>
                  <div className="targetText">
                    <div>Farmer</div>
                  </div>
                </div>
              </Link>
              <Link
                onClick={() => handleButtonSelect("Logistics")}
                className="signueachm"
              >
                <div
                  className={`toggle-button ${
                    selectedButton === "Logistics"
                      ? "signueach1ms"
                      : "signueach1m"
                  }`}
                >
                  <div className="targetIcons">
                    <AiOutlineCar />
                  </div>
                  <div className="targetText">
                    <div>Logistics</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {selectedButton === "customer" && <RecoverAccountC />}
        {selectedButton === "store" && <RecoverAccountS />}
        {selectedButton === "farm" && <RecoverAccountF />}
        {selectedButton === "company" && <RecoverAccountCo />}
        {selectedButton === "Logistics" && <RecoverAccountL />}
      </div>
    </div>
  );
}
