import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/cart.css";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/footer/Footer";
import FMFooter from "../../Components/footer/FMFooter";
import ProfileMenu from "../../Components/profile/profileMenu";
import { BsCart4 } from "react-icons/bs";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
// import { UserContext } from "../context/UserContext";

const CompanyCart = ({ companyCartItems }) => {
  const { cartId } = useParams();
  const [companyCart, setCompanyCart] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [deliveryOption, setDeliveryOption] = useState("");
  const [pickupLocations, setPickupLocations] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [selectedPickupLocation, setSelectedPickupLocation] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [logisticsVehicles, setLogisticsVehicles] = useState([]);
  const [selectedLogisticsVehicle, setSelectedLogisticsVehicle] = useState("");
  const [selectedVehicleDetails, setSelectedVehicleDetails] = useState(null);
  // const { userInfo } = useContext(UserContext);
  const [teller, setTeller] = useState(null);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchCompanyCartItems = async () => {
      try {
        const response = await axios({
          url: `/api/v1/companycart/${cartId}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
        });
        setCompanyCart(response.data.cart.items);
        setDataLoaded(true);

        const companyLocations = response.data.companyLocations;
        const accountDetails = response.data.accountDetails;
        const pickupLocationsData = [];
        companyLocations.forEach((company) => {
          company.locations.forEach((location) => {
            pickupLocationsData.push({
              id: location._id,
              name: location.address,
              address: location.city,
              companyId: company.companyId,
              companyName: company.companyName,
              coordinates: location.coordinates,
            });
          });
        });
        setDeliveryOption(response.data.cart.deliveryOption);
        setDeliveryAddress(response.data.cart.deliveryAddress);
        setSelectedLogisticsVehicle(response.data.cart.logistics);

        if (response.data.cart.logistics) {
          const vehicle = logisticsVehicles.find(
            (vehicle) => vehicle._id === response.data.cart.logistics
          );
          setSelectedVehicleDetails(vehicle);
        }
        setTotalAmount(response.data.totalAmount);
        setPickupLocations(pickupLocationsData);
        setAccountDetails(accountDetails);
        setIsApproved(response.data.cart.isApproved);
        setSelectedPickupLocation(response.data.cart.pickupLocation);
        // const selectedVehicle = logisticsVehicles.find(vehicle => vehicle._id === response.data.logistics);
        // setSelectedVehicleDetails(selectedVehicle);
      } catch (error) {
        console.error("Error fetching company cart data:", error);
      }
    };

    fetchCompanyCartItems();
  }, [logisticsVehicles, selectedLogisticsVehicle, cartId]);

  const handleRemoveItem = async (itemId) => {
    try {
      const response = await axios.delete("/api/v1/companycart", {
        data: { product: itemId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setCompanyCart(response.data.cart.items);
    } catch (error) {
      console.error("Error removing item from the company cart:", error);
    }
  };

  const handleQuantityChange = async (itemId, newQuantity) => {
    try {
      const response = await axios.put(
        "/api/v1/companycart",
        {
          product: itemId,
          quantity: newQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setCompanyCart(response.data.cart.items);

      const updatedTotalAmount = response.data.totalAmount;
      setTotalAmount(updatedTotalAmount);
    } catch (error) {
      console.error("Error updating item quantity in the company cart:", error);
    }
  };

  const handleApprove = async () => {
    try {
      const response = await axios.put(
        `/api/v1/companycart/approve`,
        {cartId},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIsApproved(response.data.isApproved);
      window.location = "/myprofile/CompanyCartList";
    } catch (error) {
      console.error("Error approving company cart:", error);
    }
  };

  const handleCheckout = async () => {
    try {
      const orderData = {};

      await axios.post("/api/v1/companyorder/create", orderData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      window.location = "/myprofile/CompanyOrderList";
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const handleDeliveryOptionChange = async (option) => {
    setDeliveryOption(option);

    if (option === "delivery") {
      setDeliveryOption("delivery");
    } else if (option === "pickup") {
      try {
        const response = await axios.put("/api/v1/companycart/pickup", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setSelectedLogisticsVehicle();
        const updatedTotalAmount = response.data.totalAmount;
        setTotalAmount(updatedTotalAmount);
      } catch (error) {
        console.error("Error handling delivery option change:", error);
      }
    }
  };

  const handleSelectPickupLocation = async (locationId, companyId) => {
    try {
      setSelectedPickupLocation(locationId);

      await axios.put(
        "/api/v1/companycart/addlocation",
        {
          companyLocationId: locationId,
          companyId: companyId,
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error setting pickup location:", error);
    }
  };

  const handleSelectLogisticsVehicle = async (vehicleId) => {
    setSelectedLogisticsVehicle(vehicleId);
    console.log(deliveryAddress);
    const selectedVehicle = logisticsVehicles.find(
      (vehicle) => vehicle._id === vehicleId
    );
    setSelectedVehicleDetails(selectedVehicle);
    const response = await axios.put(
      "/api/v1/companycart/addvehicle",
      {
        logistics: vehicleId,
        deliveryAddress: deliveryAddress,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    setSelectedLogisticsVehicle(response.data.logistics);
  };

  const handleAddressChange = async (newAddress) => {
    try {
      setDeliveryAddress(newAddress);

      if (selectedLogisticsVehicle) {
        const response = await axios.put(
          "/api/v1/companycart/addvehicle",
          {
            logistics: selectedLogisticsVehicle,
            deliveryAddress: newAddress,
          },
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setSelectedLogisticsVehicle(response.data.logistics);
      }
    } catch (error) {
      console.error("Error updating address and vehicle:", error);
    }
  };

  const handleUploadTeller = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("teller", file);
    try {
      const response = await axios.put(
        "/api/v1/companycart/uploadteller",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setTeller(response.data.teller);
      console.log(teller);
    } catch (error) {
      console.error("Error uploading teller:", error);
    }
  };

  useEffect(() => {
    if (selectedPickupLocation) {
      const handleSearchLogistics = async () => {
        try {
          const selectedLocation = pickupLocations.find(
            (location) => location.id === selectedPickupLocation
          );

          if (selectedLocation) {
            const [longitude, latitude] =
              selectedLocation.coordinates.coordinates;

            const response = await axios.put(
              "/api/v1/logisticsvehicle/cartVehicles",
              {
                latitude,
                longitude,
              },
              {
                headers: {
                  // "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            setLogisticsVehicles(response.data.logisticsVehicles);
          }
        } catch (error) {
          console.error("Error fetching logistics companies:", error);
        }
      };

      handleSearchLogistics();
    }
  }, [selectedPickupLocation, pickupLocations]);

  return (
    <div>
      <Navbar />
      <div className="profile">
        <ProfileMenu />
        <div className="orderDetailContainer">
          <div>
            {dataLoaded ? (
              companyCart.length === 0 ? (
                <div>
                  <BsCart4 size={200} />
                  <p>
                    You've not picked any product into your cart from any nearby
                    companies.
                  </p>
                </div>
              ) : (
                <div className="detailCart1">
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="2" className="detailCartEach1">
                          Product Details
                        </th>
                        <th className="detailCartEach">Quantity</th>
                        <th className="detailCartEach">Subtotal</th>
                        <th className="detailCartEach">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyCart.map((item) => (
                        <tr key={item._id}>
                          <td className="detailCartEach1">
                            <img
                              src={item.product.images[0]}
                              alt={item.product.productName}
                              className="otherImages"
                            />
                          </td>
                          <td className="detailCartEach1">
                            <h4>{item.product.productName}</h4>
                          </td>
                          <td className="CartQuantityCont">
                            <button
                              onClick={() =>
                                handleQuantityChange(
                                  item.product._id,
                                  item.quantity - 1
                                )
                              }
                            >
                              <FaLessThan />
                            </button>
                            {item.quantity}
                            <button
                              onClick={() =>
                                handleQuantityChange(
                                  item.product._id,
                                  item.quantity + 1
                                )
                              }
                            >
                              <FaGreaterThan />
                            </button>
                          </td>
                          <td className="detailCartEach1">
                            &#x20A6;{item.quantity * item.product.price}
                          </td>
                          <td className="detailCartEach">
                            <div
                              onClick={() => handleRemoveItem(item.product._id)}
                            >
                              <MdDelete size="20" className="cartBasket" />
                              Remove item
                            </div>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="3" className="detailCartEach1">
                          Total
                        </td>
                        <td colSpan="2" className="detailCartEach1">
                          &#x20A6;
                          {totalAmount}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            ) : (
              <p>Loading cart data...</p>
            )}

            <div className="cartLogCont">
              <div>
                <label className="deliveryOption">
                  Delivery Option:
                  <select
                    value={deliveryOption}
                    className="options"
                    onChange={(e) => handleDeliveryOptionChange(e.target.value)}
                  >
                    <option value="pickup">Pickup myself</option>
                    <option value="delivery">Delivery to me</option>
                  </select>
                </label>
              </div>

              <div>
                <label className="deliveryOption">
                  Pickup Location:
                  <select
                    value={selectedPickupLocation || ""}
                    className="options"
                    onChange={(e) => {
                      const selectedOption = e.target.value.split("_"); // Splitting the value to extract locationId and companyId
                      handleSelectPickupLocation(
                        selectedOption[0],
                        selectedOption[1]
                      );
                    }}
                  >
                    <option value="">
                      {selectedPickupLocation
                        ? pickupLocations.find(
                            (location) => location.id === selectedPickupLocation
                          )?.name +
                          " - " +
                          pickupLocations.find(
                            (location) => location.id === selectedPickupLocation
                          )?.address
                        : "Select company location"}
                    </option>
                    {pickupLocations.map((location) => (
                      <option
                        key={location.id}
                        value={`${location.id}_${location.companyId}`}
                      >
                        {location.name} - {location.address}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <button className="order-details-btn3" onClick={handleApprove}>
                Approve
              </button>

              <div>
                {isApproved ? (
                  <h3>You have approved this transaction</h3>
                ) : (
                  <div>
                    <h4>
                      You've not approved this transaction.
                    </h4>
                  </div>
                )}

                <label className="deliveryOption"></label>
              </div>
              {/* <div className="order-details-btns">
                <button className="order-details-btn3" onClick={handleCheckout}>
                  Checkout
                </button>
              </div> */}

              {/* <div className="order-details-btns">
          <PaystackButton {...componentProps} className="order-details-btn2" />
          <button
            className="order-details-btn3"
            onClick={() => createOrderWithWallet()}
          >
            Pay with Wallet
          </button>
          <PaystackButton {...componentProps} className="order-details-btn2" />
        </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
};

export default CompanyCart;
