import React, { useState, useContext } from "react";
import axios from "axios";
// import Loader from "react-loader-spinner";
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { BiArrowBack } from "react-icons/bi";

import "../../styles/waitlist.css";
import Navbar from "../Navbar";

function LogisticsUpload() {
  const { setUserInfo } = useContext(UserContext);

  const navigate = useNavigate();

  const [vehicleType, setVehicleType] = useState("");
  const [plateNum, setPlateNum] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.success("Vehicle uploaded successfully.", "Success Message");
  };

  const displayError1 = () => {
    toastr.warning("This might take sometime.", "Please be patientError");
  };

  const displayError = (x) => {
    toastr.error(
      x,
      "Error: Please check and fill the form again. File means the image you uploaded"
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    displayError1();
    setIsLoading(true);

    let formData = new FormData();

    formData.append("vehicleType", vehicleType);
    formData.append("plateNum", plateNum);
    formData.append("price", price);
    formData.append("image", image[0]);

    try {
      await axios.post("api/v1/logisticsvehicle/", formData, {
        // withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      //   console.log(response.data);
      notify();
      newRedirect();
      setIsLoading(false);
    } catch (error) {
      displayError(error.response.data.message);
      setIsLoading(false);
    }
  };

  const newRedirect = async (e) => {
    await axios({
      url: "api/v1/logistics/profile",
      // withCredentials: true,
      method: "GET",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        const userInfoData = response.data;
        setUserInfo(userInfoData);
        // console.log(userInfoData);
        navigate("/myprofile/MyVehicles");
      })
      .catch((err) => {
        // console.log(err.response.data.message)
        // console.log(formData)
        displayError(err.response.data.message);
      });
  };

  return (
    <div>
      <Navbar />
      <div className="container1">
        <div className="goBack">
          <button onClick={() => navigate(-1)} className="goBack">
            <BiArrowBack /> Go back
          </button>
        </div>
        <br />
        <form className="post_blog" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Vehicle Type</label>
            <select
              onChange={(e) => setVehicleType(e.target.value)}
              value={vehicleType}
              className="form_input"
            >
              <option value="">Pick vehicle Type</option>
              <option value="truck">Truck</option>
              <option value="van">Van</option>
              <option value="car">Car</option>
              <option value="bike">Bike</option>
            </select>
          </div>
          <div className="waitlist_post">
            <label className="form_label">Plate Number</label>
            <input
              type="text"
              onChange={(e) => setPlateNum(e.target.value)}
              value={plateNum}
              className="form_input"
              placeholder={"Enter Vehicle Plate Number here."}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Price</label>
            <input
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              className="form_input"
              placeholder={"How much is delivery with same city."}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Vehicle Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files)}
              className="custom-file-input"
              accept="image/*"
            />
            <br />

            <div className="image-preview-container">
              {image &&
                Array.from(image).map((imag, index) => (
                  <div key={index} className="image-preview">
                    <img
                      src={URL.createObjectURL(imag)}
                      alt={`Imag ${index}`}
                    />
                  </div>
                ))}
            </div>
          </div>
          <p>
                By submitting this form you are accepting{" "}
                <Link to="/tandc" className="tandc">
                  <b>FarmyApp Terms of Use</b>
                </Link>
              </p>

          <button className="pos_bt" disabled={isLoading}>
            {isLoading ? <span className="loading-spinner"></span> : "Submit"}
          </button>
          {/* <button className="pos_bt" >Submit</button> */}
        </form>
      </div>
    </div>
  );
}

export default LogisticsUpload;
